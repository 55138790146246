import React from "react";

import Layout from "../components/Layout";

const Page = () => {
   return (
      <Layout>
         <section className="pt-12 md:pt-16 pb-22 md:pb-32">
            <div className="container">
               <h1>Privacy Policy</h1>
               <p>
                  Protecting your private information is our priority. This Statement of Privacy applies to www.consciouslegal.law, and Conscious
                  Legal, APC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to
                  Conscious Legal, APC include www.consciouslegal.law. The Conscious Legal, APC website is a general informational site. By using the
                  Conscious Legal, APC website, you consent to the data practices described in this statement. 
               </p>
               <h3>Collection of your Personal Information</h3>
               <p>
                  In order to better provide you with products and services offered, Conscious Legal, APC may collect personally identifiable
                  information, such as your: 
               </p>
               <p>
                  - First and Last Name 
                  <br />
                  - E-mail Address 
                  <br />- Phone Number 
               </p>
               <p>
                  We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to
                  provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering
                  for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from
                  selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering
                  and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in
                  relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information
                  in the future. 
               </p>
               <h3>Use of your Personal Information </h3>
               <p>Conscious Legal, APC collects and uses your personal information to operate and deliver the services you have requested. </p>
               <p>
                  Conscious Legal, APC may also use your personally identifiable information to inform you of other products or services available
                  from Conscious Legal, APC and its affiliates. 
               </p>
               <h3>Sharing Information with Third Parties</h3>
               <p>Conscious Legal, APC does not sell, rent or lease its customer lists to third parties. </p>
               <p>
                  Conscious Legal, APC may share data with trusted partners to help perform statistical analysis, send you email or postal mail,
                  provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information
                  except to provide these services to Conscious Legal, APC, and they are required to maintain the confidentiality of your
                  information. 
               </p>
               <p>
                  Conscious Legal, APC may disclose your personal information, without notice, if required to do so by law or in the good faith belief
                  that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Conscious Legal, APC
                  or the site; (b) protect and defend the rights or property of Conscious Legal, APC; and/or (c) act under exigent circumstances to
                  protect the personal safety of users of Conscious Legal, APC, or the public. 
               </p>
               <h3>Automatically Collected Information</h3>
               <p>
                  Information about your computer hardware and software may be automatically collected by Conscious Legal, APC. This information can
                  include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the
                  operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Conscious
                  Legal, APC website. 
               </p>
               <h3>Use of Cookies</h3>
               <p>
                  The Conscious Legal, APC website may use "cookies" to help you personalize your online experience. A cookie is a text file that is
                  placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies
                  are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. 
               </p>
               <p>
                  One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the
                  Web server that you have returned to a specific page. For example, if you personalize Conscious Legal, APC pages, or register with
                  Conscious Legal, APC site or services, a cookie helps Conscious Legal, APC to recall your specific information on subsequent visits.
                  This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When
                  you return to the same Conscious Legal, APC website, the information you previously provided can be retrieved, so you can easily use
                  the Conscious Legal, APC features that you customized. 
               </p>
               <p>
                  You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the
                  interactive features of the Conscious Legal, APC services or websites you visit. 
               </p>
               <h3>Links</h3>
               <p>
                  This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such
                  other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that
                  collects personally identifiable information. 
               </p>
               <h3>Right to Deletion</h3>
               <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: </p>
               <ul className="list-disc ml-6">
                  <li>
                     <p>Delete your personal information from our records; and </p>
                  </li>
                  <li>
                     <p>Direct any service providers to delete your personal information from their records. </p>
                  </li>
               </ul>
               <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: </p>
               <ul className="list-disc ml-6">
                  <li>
                     <p>
                        Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product
                        recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within
                        the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; 
                     </p>
                  </li>
                  <li>
                     <p>
                        Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those
                        responsible for that activity; 
                     </p>
                  </li>
                  <li>
                     <p>Debug to identify and repair errors that impair existing intended functionality; </p>
                  </li>
                  <li>
                     <p>
                        Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another
                        right provided for by law; 
                     </p>
                  </li>
                  <li>
                     <p>Comply with the California Electronic Communications Privacy Act; </p>
                  </li>
                  <li>
                     <p>
                        Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all
                        other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously
                        impair the achievement of such research, provided we have obtained your informed consent; 
                     </p>
                  </li>
                  <li>
                     <p>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </p>
                  </li>
                  <li>
                     <p>Comply with an existing legal obligation; or </p>
                  </li>
                  <li>
                     <p>
                        Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you
                        provided the information. 
                     </p>
                  </li>
               </ul>
               <h3>Children Under Thirteen</h3>
               <p>
                  Conscious Legal, APC does not knowingly collect personally identifiable information from children under the age of thirteen. If you
                  are under the age of thirteen, you must ask your parent or guardian for permission to use this website. 
               </p>
               <h3>E-mail Communications</h3>
               <p>
                  From time to time, Conscious Legal, APC may contact you via email for the purpose of providing announcements, promotional offers,
                  alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification
                  when you open an email from Conscious Legal, APC or click on a link therein. 
               </p>
               <p>
                  If you would like to stop receiving marketing or promotional communications via email from Conscious Legal, APC, you may opt out of
                  such communications by clicking on the UNSUBSCRIBE button. 
               </p>
               <h3>Changes to this Statement</h3>
               <p>
                  Conscious Legal, APC reserves the right to change this Privacy Policy from time to time. We will notify you about significant
                  changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by
                  placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or
                  Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
                  agreement to abide and be bound by that Policy. 
               </p>
               <h3>Contact Information</h3>
               <p>
                  Conscious Legal, APC welcomes your questions or comments regarding this Statement of Privacy. If you believe that Conscious Legal,
                  APC has not adhered to this Statement, please contact Conscious Legal, APC at: 
               </p>
               <p>
                  Conscious Legal, APC 
                  <br />
                  3919 30<sup>th</sup> Street,
                  <br />
                  San Diego, California 92104 
               </p>
               <p>
                  Email Address: 
                  <br />
                  info@consciouslegal.law 
               </p>
               <p>
                  Telephone number: 
                  <br />
                  6199873532 
               </p>
               <p>Effective as of April 20, 2021 </p>
            </div>
         </section>
      </Layout>
   );
};

export default Page;
